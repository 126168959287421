
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CFormSelect from "@/components/form/Select.vue";
import CFormInputText from "@/components/form/InputText.vue";
import PDropdown from "primevue/dropdown";
import { SpecialtyType } from "@/store/specialty/types";

import { useFilter } from "@/libs/utils";

const VAgendaFilter = defineComponent({
  name: "VAgendaFilter",
  components: { CFormInputText, CFormSelect, PDropdown },
  setup() {
    useHead({ title: "Filtrar agendas | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasAnyFilters } = useFilter();

    const agendaType: { [key: string]: SpecialtyType } = {
      consultation: "C",
      exam: "E",
      laboratory: "L",
      procedure: "P",
    };
    const ie_tipo = agendaType[route.name?.toString().replace("agenda-", "").replace("-filter", "") || "consultation"];

    const specialties = computed(() => store.state.specialty.establishment);
    const procedures = computed(() => store.state.procedure.establishment);

    const filters = reactive({
      cd_especialidade: Number(route.query.cd_especialidade) || undefined,
      nm_especialista: route.query.nm_especialista || undefined,
      cd_procedimento_estabelecimento: Number(route.query.cd_procedimento_estabelecimento) || undefined,
      ie_status: route.query.ie_status || undefined,
    });
    const loading = reactive({ submit: false, specialties: false, procedures: false });
    const agendaStatus = [
      { label: "Habilitadas", value: "true" },
      { label: "Desabilitada", value: "false" },
    ];

    async function applyFilters() {
      router.replace({ query: { ...route.query, ...filters } });

      loading.submit = true;
      await store.dispatch("searchAgendas", {
        search: { ...filters, ie_tipo_agenda: ie_tipo },
        isPrimary: true,
      });
      loading.submit = false;
    }

    async function getEstablishmentSpecialty() {
      loading.specialties = true;
      await store.dispatch("getEstablishmentSpecialty", { ie_tipo: "C" });
      loading.specialties = false;
    }

    async function getEstablishmentProcedure() {
      loading.procedures = true;
      await store.dispatch("getEstablishmentProcedure", { ie_grupo: ie_tipo });
      loading.procedures = false;
    }

    function resetFilters() {
      filters.nm_especialista = undefined;
      filters.cd_procedimento_estabelecimento = undefined;
      filters.cd_especialidade = undefined;
      filters.ie_status = undefined;

      applyFilters();
    }

    function handleClose() {
      router.replace({ name: route.name?.toString().replace("-filter", ""), query: route.query });
    }

    getEstablishmentProcedure();
    if (ie_tipo === "C") getEstablishmentSpecialty();
    if (hasAnyFilters.value) applyFilters();

    return {
      filters,
      loading,
      hasAnyFilters,
      procedures,
      specialties,
      ie_tipo,
      getEstablishmentSpecialty,
      applyFilters,
      resetFilters,
      handleClose,
      agendaStatus,
    };
  },
});

export default VAgendaFilter;
